import { all, takeEvery, put, call } from 'redux-saga/effects'
import { currentAccount, logout, login, signup, putProfile } from 'services/user'
import { push } from 'react-router-redux'
import userActions from './actions'
import snapshotActions from '../snapshot/actions'
import veriglifActions from '../veriglif/actions'
import i18n from 'i18n/config' 

export function* LOGIN({ payload }) {
  const { email, password } = payload

  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const success = yield call(login, email, password)

  if (success) {
    yield put({
      type: userActions.LOAD_CURRENT_ACCOUNT,
    })
  }

  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* SIGNUP({ payload }) {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  let success = yield call(signup, payload)

  if (success === 'UserNotConfirmedException') {
    yield put(push('/login'))
    localStorage.removeItem('signUpData');
    window.location.reload();
    success = false
  }

  if (success) {
    yield put({
      type: userActions.LOAD_CURRENT_ACCOUNT,
    })
  }

  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(currentAccount)
  if (response) {
    const {
      uid: id,
      email,
      firstName,
      lastName,
      gender,
      country,
      jobFunction,
      veriglifAuth,
      role,
      companyCode,
      logos = [],
      privacyPolicyLink = '',
      qualifySearchDomains = [],
      terms = [],
      whitelists = [],
      blacklists = [],
      excludedFiles = [],
    } = response

    terms.push('Default')
    blacklists.push('Market Research Blacklist')

    yield put({
      type: userActions.SET_STATE,
      payload: {
        id,
        firstName,
        lastName,
        gender,
        country,
        jobFunction,
        email,
        role,
        companyCode,
        logos,
        privacyPolicyLink,
        qualifySearchDomains,
        terms,
        whitelists,
        blacklists,
        excludedFiles,
        authorized: true,
      },
    })
    yield put({
      type: 'veriglif/SET_STATE',
      payload: {
        authorized: veriglifAuth,
      },
    })
  }

  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(logout);
  // Clear temporary language but keep user preference
  localStorage.removeItem('lng');
  if (navigator.language) {
    localStorage.setItem('lng', navigator.language);
    i18n.changeLanguage(navigator.language);
  }
  yield put({
    type: userActions.RESET_STATE,
  });
  yield put({
    type: snapshotActions.RESET_STATE,
  });
  yield put({
    type: veriglifActions.RESET_STATE,
  });
}

export function* PUT_PROFILE({ payload }) {
  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const success = yield call(putProfile, payload)

  if (success) {
    if (payload.type === 'Details')
      yield put({
        type: userActions.SET_STATE,
        payload: {
          firstName: payload.data.firstName,
          lastName: payload.data.lastName,
          country: payload.data.country,
          gender: payload.data.gender,
          jobFunction: payload.data.jobFunction,
        },
      })
    else if (payload.type === 'Email')
      yield put({
        type: userActions.SET_STATE,
        payload: {
          email: payload.data.email,
        },
      })
  }

  yield put({
    type: userActions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

// Worker Saga: Will be fired on SAVE_SIGNUP_DATA action
function saveSignUpDataSaga(action) {
  try {
    // Save data to local storage
    localStorage.setItem('signUpData', JSON.stringify(action.payload));
  } catch (error) {
    console.error('Failed to save signup data:', error);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(userActions.LOGIN, LOGIN),
    takeEvery(userActions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(userActions.LOGOUT, LOGOUT),
    takeEvery(userActions.SIGNUP, SIGNUP),
    takeEvery(userActions.PUT_PROFILE, PUT_PROFILE),
    takeEvery(userActions.SAVE_SIGNUP_DATA, saveSignUpDataSaga),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
