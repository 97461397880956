import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Avatar, Menu } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import styles from './style.module.scss';
import i18n from 'i18next';

const mapStateToProps = ({ user }) => ({
  user
});

const LanguageMenu = ({ user }) => {
  // Explicitly track auth state
  // eslint-disable-next-line 
  const isAuthorized = useMemo(() => user?.authorized, [user?.authorized]);

  const getCurrentLanguage = useCallback(() => {
    if (isAuthorized) {
      // For logged-in users, prefer their saved preference
      const userPref = localStorage.getItem('userLangPreference');
      if (userPref) {
        // Sync i18n with user preference
        localStorage.setItem('lng', userPref);
        i18n.changeLanguage(userPref);
        return userPref;
      }
    }
    // For logged-out users or no preference, use current lng
    return localStorage.getItem('lng') || navigator.language;
  }, [isAuthorized]);

  const [language, setLanguage] = React.useState(getCurrentLanguage());

  // Update language when auth state changes
  React.useEffect(() => {
    const newLang = getCurrentLanguage();
    setLanguage(newLang);
    i18n.changeLanguage(newLang);
  }, [getCurrentLanguage]);

  const handleLanguageChange = (langCode) => {
    if (isAuthorized) {
      // Store user preference
      localStorage.setItem('userLangPreference', langCode);
    }
    // Always update current language
    localStorage.setItem('lng', langCode);
    setLanguage(langCode);
    i18n.changeLanguage(langCode);
    window.location.reload();
  };

  const menu = (
    <Menu selectable={false}>
      <Menu.Item className={language?.startsWith('en') ? styles.languageSelected : ''}>
        <div
          onClick={() => handleLanguageChange('en_US')}
          className={styles.lnglocale}
        >
          <img src="/resources/flags/us.svg" className={`${styles.menuIcon}`} alt="en" />
          <span>EN</span>
        </div>
      </Menu.Item>
      <Menu.Item className={language?.startsWith('de') ? styles.languageSelected : ''}>
        <div
          onClick={() => handleLanguageChange('de')}
          className={styles.lnglocale}
        >
          <img src="/resources/flags/de.svg" className={`${styles.menuIcon}`} alt="de" />
          <span>DE</span>
        </div>
      </Menu.Item>
      <Menu.Item className={language?.startsWith('th') ? styles.languageSelected : ''}>
        <div
          onClick={() => handleLanguageChange('th_TH')}
          className={styles.lnglocale}
        >
          <img src="/resources/flags/th.svg" className={`${styles.menuIcon}`} alt="th" />
          <span>TH</span>
        </div>
      </Menu.Item>
      <Menu.Item className={language?.startsWith('id') ? styles.languageSelected : ''}>
        <div
          onClick={() => handleLanguageChange('id')}
          className={styles.lnglocale}
        >
          <img src="/resources/flags/id.svg" className={`${styles.menuIcon}`} alt="id" />
          <span>ID</span>
        </div>
      </Menu.Item>
      <Menu.Item className={language?.startsWith('zh') ? styles.languageSelected : ''}>
        <div
          onClick={() => handleLanguageChange('zh-TW')}
          className={styles.lnglocale}
        >
          <img src="/resources/flags/cn.svg" className={`${styles.menuIcon}`} alt="zh" />
          <span>CN</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Avatar className={styles.avatar} shape="square" size="large" icon={<GlobalOutlined />} />
    </Dropdown>
  );
};

export default connect(mapStateToProps)(LanguageMenu);